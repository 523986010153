/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-13 17:50:44
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels } from '@/plugins/widget/c-form/rules';

export var models = genModels(function (genRules, ctx) {return [
  {
    kname: 'name',
    itemProps: {
      label: '部门名称：',
      rules: [
      genRules('require')] } },



  {
    kname: 'isManage',
    itemProps: {
      label: '是否管理部门：' },

    component: {
      name: 'c-switch',
      props: {
        intercept: false } } }



  // {
  //   key: 'xx',
  //   itemProps: {
  //     label: '部门分管领导：',
  //     rules: [
  //       genRules('require')
  //     ]
  //   },
  //   component: {
  //     name: 'selector',
  //     props: {
  //       filterable: true,
  //       list: [
  //         { label: 'sss', value: 1 },
  //         { label: 'sss2', value: 2 },
  //         { label: 'sss3', value: 3 }
  //       ]
  //     }
  //   }
  // },
  // {
  //   key: 'xx',
  //   itemProps: {
  //     label: '部门绩效专责：',
  //     rules: [
  //       genRules('require')
  //     ]
  //   },
  //   component: {
  //     name: 'selector',
  //     props: {
  //       filterable: true,
  //       list: [
  //         { label: 'sss', value: 1 },
  //         { label: 'sss2', value: 2 },
  //         { label: 'sss3', value: 3 }
  //       ]
  //     }
  //   }
  // }
  ];});


// form 默认值
export var formData = function formData() {return {};};